<template>
  <modal-agreement :footerLess="footerLess" :huge="isHuge">
    <template v-slot:body>
      <div class="agreement-pop">
        <div class="a-header">
          <div class="img-wrap">
            <img src="@/assets/images/logo_default.svg" />
          </div>
          <span> 한 눈에, 닥터리퍼.</span>
        </div>
        <div class="a-content marketting">
          <div class="" v-if="agreeCheckNum === 2">
            <div class="text-wrap">
              <h4 class="title">진료만족도 평가수신</h4>
              <h6 class="sub">1. 평가 수집이용 목적</h6>

              <p>
                - 닥터리퍼(DrRefer)에서는 더 좋은 의료 서비스 제공을 위해 만족도
                평가를 시행하고 있습니다.
              </p>
            </div>
          </div>
          <div class="" v-if="agreeCheckNum === 3">
            <div class="text-wrap">
              <h4 class="title">피드백 수신동의</h4>
              <h6 class="sub">1. 개인정보의 수집이용 목적</h6>

              <p>
                - 닥터리퍼(DrRefer)에서 제공하는 이벤트 정보를 앱 푸시 알림,
                이메일, 휴대폰 문자로 전송
              </p>
              <h6 class="sub">2. 수집하려는 개인정보의 항목</h6>
              <p>- 개인식별정보: 성명, 성별, e-mail, 휴대폰번호, 주소 등</p>
              <p>- 회원 ID, 접속 일시, 광고식별자(ADID, IDFA), SNS 아이디 등</p>
              <h6 class="sub">3. 개인정보의 보유 및 이용기간</h6>
              <p>
                - 이용자가 동의를 철회하거나 이용자의 탈퇴시까지 보유 및
                이용합니다.
              </p>
              <p>
                - 마케팅 정보 수신을 위한 개인정보 수집, 이용에 동의할 경우,
                귀하의 연락처, 이메일, 앱 푸시 알림을 통하여 재화나 서비스의
                홍보 또는 판매 권유 등을 위한 연락을 할 수 있습니다.
              </p>
              <p>
                - 귀하는 마케팅 정보 수신을 위한 개인정보 수집, 이용을 거부할 수
                있으며, 거부 시에는 마케팅정보 수신이 제한되지만, 닥터리퍼
                서비스는 계속 이용할 수 있습니다.
              </p>
              <p></p>
              <p></p>
              <p>
                ※마케팅 수신동의를 하시면, 각종 이벤트 정보를 빠르게 안내 받을
                수 있습니다.
              </p>
            </div>
          </div>
          <div class="" v-if="agreeCheckNum === 1">
            <div class="text-wrap">
              <h4 class="title">환자연락처 열람동의</h4>
              <h6 class="sub">1. 개인정보의 수집이용 목적</h6>

              <p>
                - 닥터리퍼(DrRefer)에서 제공하는 서비스를 위해 앱 푸시 알림,
                이메일, 휴대폰 문자로 전송
              </p>
              <h6 class="sub">수집하려는 개인정보의 항목</h6>
              <p>- 개인식별정보: 성명, 성별, e-mail, 휴대폰번호, 주소 등</p>
              <p>- 회원 ID, 접속 일시, 광고식별자(ADID, IDFA), SNS 아이디 등</p>
              <h6 class="sub">3. 개인정보의 보유 및 이용기간</h6>
              <p>
                - 이용자가 동의를 철회하거나 이용자의 탈퇴시까지 보유 및
                이용합니다.
              </p>
              <p>
                - 닥터리퍼 서비스를 위한 개인정보 수집, 이용에 동의할 경우,
                귀하의 연락처, 이메일, 앱 푸시 알림을 통하여 연락을 할 수
                있습니다.
              </p>
              <p>
                - 귀하는 개인정보 수집, 이용을 거부할 수 있으며, 거부 시에도
                닥터리퍼 서비스는 계속 이용할 수 있습니다.
              </p>
            </div>
          </div>
          <div class="text-wrap" v-if="agreeCheckNum == 0">
            <h4 class="title">닥터리퍼 이용약관 (필수)</h4>
            <h6 class="sub">제1장 총칙</h6>
            <h6 class="sub">제1조 (목적)</h6>
            <p>
              <span
                >헬스앤모어㈜(이하 &lsquo;회사&rsquo;라 한다)에서 운영하는
                온라인 서비스인 &lsquo;닥터리퍼(DrRefer, 이하
                &lsquo;닥터리퍼&rsquo; 또는 &lsquo;서비스&rsquo;라 한다)의
                이용에 관한 사항을 규정함을 목적으로 합니다.</span
              >
            </p>
            <h6 class="sub">제2조 (용어의 정의)</h6>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회원: &lsquo;닥터리퍼&rsquo; 서비스에 로그인하여 본 약관에
                  따라 회사가 제공하는 서비스를 받는 자를 말합니다. 회원은
                  면허증을 소지한 의사, 치과의사로 제한합니다. 단, 환자 진료의뢰
                  수신 서비스는 의원 및 병원급 의료기관에 종사하는 회원만 이용할
                  수 있습니다. 종합병원 및 상급종합병원에 근무하는 회원은 환자
                  진료의뢰 발신만 할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  >운영자: 서비스의 전반적인 관리와 원활한 운영을 위하여
                  회사에서 선정한 사람을 말합니다.</span
                >
              </li>
              <li>
                <span
                  >환자: 의료서비스가 필요한 대상으로서, &lsquo;닥터리퍼&rsquo;
                  서비스를 통해 한 회원(A)이 다른 회원(B)에게 진료를 의뢰하고
                  (진료의뢰 발신), 그 회원(B)이 진료를 의뢰받는 (진료의뢰 수신)
                  대상을 말합니다.</span
                >
              </li>
              <li>
                <span
                  >연결사이트: 닥터리퍼의 인터넷 관련 서비스(홈페이지/모바일 웹,
                  앱)와 하이퍼링크 방식(하이퍼링크의 대상에는 문자, 정지 영상 및
                  동화상 등이 포함됨) 등으로 연결된 웹 사이트를 말합니다.</span
                >
              </li>
              <li>
                <span
                  >개인정보: 당해 정보에 포함되어 있는 성명, 아이디 등의 사항에
                  의하여 특정 개인을 식별할 수 있는 정보(당해 정보만으로는 특정
                  개인을 인식할 수 없더라도 다른 정보와 용이하게 결합하여 식별할
                  수 있는 것을 포함한다)를 말합니다.</span
                >
              </li>
              <li>
                <span
                  >마일리지: 회원이 이벤트에 참가하거나 서비스를 이용함에 따라
                  회사가 발행하여 취득하는 것으로서, 회원이 특정 서비스를 이용할
                  때 지불수단으로 현금처럼 사용할 수 있지만, 회사는 회원에게
                  현금으로 지급하지는 않습니다.</span
                >
              </li>
              <li>
                <span
                  >해지: 회원이 서비스 가입 후 이용계약을 해약(회원 탈퇴) 하는
                  것을 말합니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제3조 (약관의 게시 및 변경)</h6>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 본 약관의 내용과 상호, 회사 소재지, 전자우편 주소 등을
                  회원이 알 수 있도록 닥터리퍼 서비스(홈페이지/모바일 웹, 앱)에
                  게시합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 관계 법령을 위배하지 않는 범위에서 본 약관을 개정할 수
                  있습니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 약관을 개정할 경우에는 전항과 같은 방법으로 공지 또는
                  통보함으로써 효력을 발생합니다.</span
                >
              </li>
              <li>
                <span
                  >회원이 개정약관의 적용에 동의하지 않는 경우, 회사는
                  개정약관의 내용을 적용할 수 없으며, 이 경우 회원 또는 회사는
                  자유롭게 이용 계약을 해지할 수 있습니다.
                </span>
              </li>
              <li>
                <span
                  >이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                  관계 법령 또는 상 관례에 따릅니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제4조 (서비스의 내용 및 변경)</h6>
            <div>
              <ol
                style="
                  margin-bottom: 0cm;
                  list-style-type: decimal;
                  margin-left: 0cmundefined;
                "
              >
                <li>
                  <span>닥터리퍼는 다음의 서비스를 제공합니다.</span>
                </li>
              </ol>
            </div>
            <p>
              <span
                >① 회원이 입력한 정보를 바탕으로, 회원 및 회원이 속한 병의원이
                타회원에 의해 검색될 수 있도록 하는 검색 서비스
              </span>
            </p>
            <p>
              <span
                >② 환자 진료의뢰를 위한 진료의뢰서 및 병원소개서를 작성하고
                출력할 수 있는 서비스</span
              >
            </p>
            <p>
              <span
                >③ 진료의뢰 발신 및 수신에 대한 정보를 관리할 수 있는
                서비스</span
              >
            </p>
            <p>
              <span>④ 회사에서 제공하는 정보 및 건강 관련 콘텐츠 </span>
            </p>
            <p>
              <span>⑤ 기타 회사가 정하는 서비스</span>
            </p>
            <ol style="list-style-type: undefined; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 불가피한 사정이 있는 경우 제공하는 서비스의 내용을
                  변경할 수 있으며, 이 경우 변경된 서비스의 내용 및 제공 일자를
                  명시하여 그 제공 일자 이전 7일부터 공지합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 서비스 내용의 변경으로 인하여 회원이 입은 손해에
                  대하여 배상하지 아니합니다. 단, 회사의 고의 또는 중과실이 있는
                  경우에는 그러하지 아니합니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제5조 (서비스의 변경 및 중단)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 시스템 등 장치의 보수 점검, 교체 및 고장, 통신의 두절,
                  기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을
                  일시적으로 중단할 수 있습니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 제1항의 사유로 서비스 제공이 일시적으로 중단됨으로
                  인하여 회원 또는 제3자가 입은 손해에 대하여는 배상하지
                  아니합니다. 단, 회사의 고의 또는 중과실이 있는 경우에는
                  그러하지 아니합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 상당한 이유가 있는 경우, 운영상, 기술상의 필요에 따라
                  제공하고 있는 서비스의 전부 또는 일부를 변경 또는 중단할 수
                  있습니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 무료로 제공하는 서비스의 일부 또는 전부를 회사의 정책
                  및 운영의 필요에 따라 변경, 중단 또는 유료화 할 수 있습니다.
                </span>
              </li>
            </ol>
            <p>
              <span> </span>
            </p>
            <h6 class="sub">제2장 회원의 가입 및 탈퇴</h6>
            <h6 class="sub">제6조 (회원 가입 및 서비스 이용 계약)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회원은 회사가 정한 양식에 따라 회원 정보를 기입한 후 본
                  약관에 동의한다는 의사표시를 함으로써 회원 가입을
                  신청합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 전항과 같이 회원으로 가입할 것을 신청한 회원 중 이하
                  각호에 해당하지 않는 한 회원으로 등록합니다.</span
                >
              </li>
            </ol>
            <p>
              <span>① 등록 내용에 허위가 있는 경우 </span>
            </p>
            <p>
              <span
                >② 가입 신청자가 본 약관 제7조 제2항에 의거하여 이전에
                회원자격을 상실한 적이 있는 경우</span
              >
            </p>
            <p>
              <span
                >③ 기타 회원으로 등록하는 것이 회사의 기술상 또는 업무 수행상
                현저히 지장이 있다고 판단하는 경우</span
              >
            </p>
            <div>
              <ol
                style="
                  margin-bottom: 0cm;
                  list-style-type: undefined;
                  margin-left: 0cmundefined;
                "
              >
                <li>
                  <span
                    >서비스 이용 계약의 성립 시기는 회원 가입 절차가 완료된
                    시점으로 합니다. 다만, 입력한 정보가 허위인 것으로
                    의심되거나 확인되면, 회사는 회원의 자격을 바로 정지하거나
                    상실 시킬 수 있습니다.</span
                  >
                </li>
                <li>
                  <span
                    >회원은 등록사항에 변경이 있는 경우, 즉시 전화 또는 기타
                    방법으로 회사에 그 변경사항을 알려야 합니다.</span
                  >
                </li>
              </ol>
            </div>
            <h6 class="sub">제7조 (회원 탈퇴 및 자격의 상실 등)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시
                  회원 탈퇴를 처리합니다. 단, 탈퇴의 요청은 닥터리퍼
                  서비스(홈페이지/모바일 웹, 앱)를 이용하여야 하며, 요청하는
                  자의 이름, 전화번호, 해지사유 등을 기재하여야 합니다.</span
                >
              </li>
              <li>
                <span
                  >회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원의
                  회원자격을 상실 시킬 수 있습니다.</span
                >
              </li>
            </ol>
            <p>
              <span>① 등록 신청 시에 허위 내용을 등록한 경우</span>
            </p>
            <p>
              <span
                >② 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                질서를 위협하는 경우</span
              >
            </p>
            <p>
              <span
                >③ 검증되지 않은 허위 정보로 환자 유치하거나 위법한 진료 행위를
                선전하는 경우</span
              >
            </p>
            <p>
              <span
                >④ 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에
                반하는 행위를 하는 경우</span
              >
            </p>
            <p>
              <span
                >⑤ 서비스 내에 제공되는 정보를 변경하는 등 닥터리퍼 서비스의
                운영을 방해한 경우</span
              >
            </p>
            <p>
              <span>⑥ 본 약관을 위반한 경우</span>
            </p>
            <p>
              <span
                >⑦ 기타 회원으로서의 자격을 지속시키는 것이 부적절하다고
                판단되는 경우</span
              >
            </p>
            <p>
              <span>3. 2</span
              ><span
                >항에 따른 사유 확인을 위해, 회사는 전문기관을 통한 실명 확인 및
                본인 인증, 입력한 회원 정보의 확인을 요청할 수 있습니다.
              </span>
            </p>
            <p>
              <strong><span>제8조 (회원에 대한 통지)</span></strong>
            </p>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 회원에 대한 통지를 하는 경우, 회원이 회사에 제공한
                  전자우편 또는 전화번호로 통지할 수 있습니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 불특정다수 회원에 대한 통지의 경우, 게시판에
                  게시함으로써 개별통지에 갈음할 수 있습니다.</span
                >
              </li>
            </ol>
            <h6 class="sub" style="color: windowtext">
              제9조 (회원 가입 정보의 이용)
            </h6>

            <p>
              <span style="color: windowtext"
                >회원 가입시 입력한 부가 정보는 아래와 같은 닥터리퍼 서비스의
                기본 정보로 이용됩니다.
              </span>
            </p>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >입력한 정보는 회원이 다른 회원을 검색하는 데 필요한 키워드로
                  사용됩니다.
                </span>
              </li>
              <li>
                <span
                  >입력한 정보를 바탕으로 회원의 소개 페이지를 자동으로
                  생성합니다.
                </span>
              </li>
              <li>
                <span
                  >입력한 정보는 다른 회원으로부터 환자를 진료의뢰 받을 수 있는
                  진료의뢰서 서식에 자동으로 입력됩니다.</span
                >
              </li>
              <li>
                <span
                  >따라서 회원 가입 정보는 검색에 도움이 될 수 있게 효율적이고
                  정확한 단어를 사용하여야 하고, 허위 정보를 입력하면 안 됩니다.
                </span>
              </li>
            </ol>
            <p>
              <span> </span>
            </p>
            <h6 class="sub">제3장 개인정보의 보호</h6>
            <h6 class="sub">제10조 (개인정보의 수집)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 회원의 정보수집 시 필요한 최소한의 정보를 수집합니다.
                  다음 사항을 필수 사항으로 </span
                ><span>하며 그 외 사항은 선택사항으로 합니다.</span>
              </li>
            </ol>
            <ul style="list-style-type: undefined; margin-left: 1cmundefined">
              <li>
                <span
                  >회원 가입 시 수집 항목: 성명, 아이디, 비밀번호, 성별,
                  생년월일, 휴대전화 번호, 이메일</span
                >
              </li>
              <li>
                <span
                  >진료의뢰 대상 환자 정보 수집 항목: 성명, 성별, 생년월일,
                  휴대전화 번호, 증상 또는 진단명</span
                >
              </li>
            </ul>
            <ol style="list-style-type: undefined; margin-left: 0cmundefined">
              <li>
                <span
                  >회원의 개인정보를 수집하는 때에는 이하 각호의 경우를
                  제외하고는 당해 회원의 동의를 받습니다.</span
                >
              </li>
            </ol>
            <p>
              <span>① 법률에 특별한 규정이 있는 경우</span>
            </p>
            <p>
              <span>② 서비스이용계약의 이행을 위해서 필요한 경우</span>
            </p>
            <p>
              <span>③ 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</span>
            </p>
            <div>
              <ol
                style="
                  margin-bottom: 0cm;
                  list-style-type: undefined;
                  margin-left: 0cmundefined;
                "
              >
                <li>
                  <span
                    >회사는 개인정보의 보호를 위하여 운영자를 한정하여 그 수를
                    최소화하며 개인정보의 분실, 도난, 유출, 변조되지 아니하도록
                    안정성 확보에 필요한 기술적 조치 등을 마련합니다.</span
                  >
                </li>
              </ol>
            </div>
            <h6 class="sub">제11조(개인정보의 수집 및 이용 목적)</h6>
            <p>
              <span
                >회사는 다음 각 호의 목적으로 회원이나 환자의 개인정보를
                수집하거나 이용할 수 있습니다.
              </span>
            </p>
            <p><span>1. </span><span>서비스 회원 가입 및 관리 </span></p>
            <p>
              <span
                >회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별 및 </span
              ><span>인증</span><span>, </span><span>회원자격 유지 및 </span
              ><span>관리</span><span>, </span
              ><span
                >제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지,
                각종 고지 및 통지, 고충 처리 등을 목적으로 개인정보를
                처리합니다.
              </span>
            </p>
            <p><span>2. </span><span>진료의뢰 서비스 제공</span></p>
            <p>
              <span
                >진료의뢰 대상 환자의 서비스 제공 동의 여부 확인, 서비스 제공 시
                대상 확인 및 서비스 내용의 관리 등을 목적으로 환자의 개인정보를
                처리합니다.
              </span>
            </p>
            <p><span>3. </span><span>기타 목적</span></p>
            <ul style="list-style-type: undefined; margin-left: 1cmundefined">
              <li>
                <span
                  >부가서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 기타 관련된
                  서비스 제공</span
                >
              </li>
              <li>
                <span
                  >서비스의 질 향상을 위한 연구 및 통계에 필요한 최소한의 분석
                  자료로 활용</span
                >
              </li>
              <li>
                <span>신규 서비스 개발을 위한 자료</span>
              </li>
            </ul>
            <p>
              <strong
                ><span style="color: #ff7334"
                  >제12조(개인정보의 보유 기간 및 이용 기간)</span
                ></strong
              >
            </p>
            <p>
              <span>회사는 법령에 따른 개인정보 보유 및 </span><span>이용</span
              ><span>
                기간 또는 정보 주체로부터 개인정보를 수집 시에 동의 받은
                개인정보 보유 및 </span
              ><span>이용</span
              ><span> 기간 내에서 개인정보를 처리 및 보유합니다.</span>
            </p>
            <div>
              <ol
                style="
                  margin-bottom: 0cm;
                  list-style-type: decimal;
                  margin-left: 0cmundefined;
                "
              >
                <li>
                  <span
                    >닥터리퍼 서비스 회원 정보의 경우, 회원 탈퇴 시까지 보유하고
                    해지 후에는 DB에서 삭제하고 보유하지 않습니다. 또한
                    개인정보가 제3자에게 제공된 경우에는 제3자에게 파기하도록
                    지시합니다. 단, 다음 각호의 경우에는 각호에 명시한 기간 동안
                    개인정보를 보유합니다.</span
                  >
                </li>
              </ol>
            </div>
            <p>
              <span
                >① 보유 기간을 회원에게 미리 고지하고 그 보유 기간이 경과하지
                아니한 경우와 개별적으로 회원의 동의를 받을 경우에 약속한 보유
                기간 동안 개인정보를 보유합니다.</span
              >
            </p>
            <p>
              <span
                >② 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의
                규정에 의해 보존할 필요성이 있는 경우에 개인정보를
                보유합니다.</span
              >
            </p>
            <p>
              <span style="color: #ff7334"
                >2. 진료의뢰 대상인 환자 정보의 경우, (내용 추가 필요)</span
              >
            </p>
            <h6 class="sub">제13조(개인정보의 이용 및 제공의 제한)</h6>

            <p>
              <span
                >제공된 개인정보는 당해 회원의 동의 없이 목적 외로 이용하거나
                제3자에게 제공할 수 없으며, 이에 따른 회원의 피해에 대한 모든
                책임은 회사가 집니다. 단, 다음의 경우에는 예외로 합니다.</span
              >
            </p>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span>법률에 특별한 규정이 있는 경우</span>
              </li>
              <li>
                <span
                  >서비스의 제공에 따른 요금 정산 및 배송 등을 위하여 필요한
                  경우</span
                >
              </li>
              <li>
                <span
                  >통계작성이나 학술연구 또는 시장조사를 위하여 필요한 경우로서
                  특정 개인을 식별할 수 없는 형태로 제공하는 경우</span
                >
              </li>
              <li>
                <span
                  >정보통신서비스 제공자로부터 회원의 개인정보를 제공받은 자는
                  당해 회원의 동의가 있거나 다른 법률에 특별한 규정이 있는
                  경우</span
                >
              </li>
            </ol>
            <h6 class="sub">제14조(회원의 권리)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회원은 언제든지 제10조 제2항의 규정에 의한 동의를 철회할 수
                  있습니다. 단, 철회의 의사표시는 회사 또는 회사로부터 정보를
                  제공받은 자에게 정보가 도달된 때로부터 유효합니다.</span
                >
              </li>
              <li>
                <span
                  >회원은 회사에게 자신의 개인정보에 대한 열람을 요구할 수
                  있으며, 자신의 개인정보에 오류가 있는 경우에는 그 정정을
                  요구할 수 있습니다.</span
                >
              </li>
              <li>
                <span
                  >제1항 및 제2항에 의한 철회, 열람, 정정의 요구는 회사에
                  전자우편을 보내는 방식으로 하여야 하며, 회사는 전자우편이
                  도달된 후 지체없이 필요한 조치를 취할 의무를 집니다. 회원이
                  오류의 정정을 요구한 경우에는 회사가 그 오류를 정정할 때까지
                  당해 개인정보를 이용하지 않습니다.</span
                >
              </li>
            </ol>
            <p>
              <span> </span>
            </p>
            <h6 class="sub">제4장 서비스에 관한 책임의 제한</h6>
            <h6 class="sub">
              제15조 (회원이 직접 입력한 정보에 기반한 병의원 검색 서비스)
            </h6>
            <p>
              <span>1. </span
              ><span
                >회원이 입력한 정보는 병의원을 검색하는 키워드로 사용되며, 검색
                결과는 병원 소개 페이지 혹은 의사 소개 페이지 형태로 표현되어
                제공됩니다.
              </span>
            </p>
            <p>
              <span>2. </span
              ><span
                >검색 결과로 나오는 병의원 및 회원 소개는 전적으로 회원이 입력한
                정보에 기반하므로, 소개 내용에 대해서는 전적으로 정보를 입력한
                회원 개인이 책임을 가집니다.</span
              >
            </p>
            <p>
              <span>3. </span
              ><span
                >회원이 입력한 정보가 거짓 정보이거나 법적 혹은 윤리적 문제가
                있다고 판단하는 경우, 회사는 제7조 2항을 적용하여 회원의 자격을
                상실 시킬 수 있습니다.</span
              >
            </p>
            <h6 class="sub">제16조 (환자 진료의뢰 서비스)</h6>
            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >닥터리퍼 서비스는 회원 간 환자 진료의뢰를 중개하는
                  서비스입니다.
                </span>
              </li>
              <li>
                <span
                  >회원과 회원간, 회원과 환자간의 직접적인 서비스 제공에는
                  관여하지 않으므로, 회원과 회원간 또는 회원과 환자간 서비스
                  관련 분쟁에는 어떠한 책임도 없습니다.
                </span>
              </li>
              <li>
                <span
                  >회원이 제공하는 서비스에도 관여하지 않으므로, 회원이 제공하는
                  서비스와 관련된 어떠한 분쟁에도 책임이 없습니다.
                </span>
              </li>
            </ol>
            <h6 class="sub">제17조 (회사와 연결사이트 간의 관계)</h6>
            <p>
              <span
                >회사는 연결사이트가 독자적으로 제공하는 재화, 용역, 서비스에
                의하여 회원과 행하는 거래에 대해서 어떠한 보증책임을 지지
                않습니다.</span
              >
            </p>
            <p>
              <span> </span>
            </p>
            <h6 class="sub">제5장 회사 및 회원의 의무</h6>
            <h6 class="sub">제18조 (회사의 의무)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를
                  하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로
                  서비스를 제공하는 데 최선을 다합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 회원의 신용정보를 포함한 개인신상정보의 보안에 대하여
                  기술적 안전 조치를 마련하고 관리에 만전을 기함으로써 회원의
                  정보보안에 최선을 다합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 공정하고 건전한 운영과 지속적인 연구 개발을 통하여
                  양질의 서비스를 제공함으로써 고객 만족을 극대화하여 인터넷
                  비즈니스 발전에 기여하도록 합니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 회원이 원하지 않는 영리 목적의 광고성 전자우편을
                  발송하지 않습니다.</span
                >
              </li>
            </ol>

            <h6 class="sub">
              제19조 (회원의 로그인 아이디 및 비밀번호 관리에 대한 의무)
            </h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회원은 서비스를 이용하는 경우 휴대폰번호(본인인증) 및
                  비밀번호를 사용해 회원가입 및 로그인을 하여야 합니다.</span
                >
              </li>
              <li>
                <span
                  >회원의 로그인 아이디와 비밀번호에 관한 관리의 모든 책임은
                  회원에게 있습니다.</span
                >
              </li>
              <li>
                <span
                  >회원은 자신의 로그인 아이디 및 비밀번호를 제3자에게 이용하게
                  해서는 안 됩니다.</span
                >
              </li>
              <li>
                <span
                  >회원은 로그인 아이디 및 비밀번호를 도난 당하거나 제3자에게
                  사용되고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의
                  안내가 있는 경우에는 그에 따라야 합니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제20조 (회원의 의무)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span>회원은 다음의 행위를 하여서는 안 됩니다.</span>
              </li>
            </ol>
            <ul style="list-style-type: undefined; margin-left: 18.3px">
              <li>
                <span>신청 또는 변경 시 허위 내용의 등록</span>
              </li>
              <li>
                <span
                  >외설 또는 폭력적인 메시지, 영상, 음성, 기타 공서양속에 반하는
                  정보를 서비스에 공개 또는 게시하는 행위</span
                >
              </li>
              <li>
                <span
                  >회사가 정한 정보 이외의 정보(컴퓨터프로그램 등) 송신 또는
                  게시</span
                >
              </li>
              <li>
                <span>회사 기타 제3자의 저작권 등 지적 재산권에 대한 침해</span>
              </li>
              <li>
                <span
                  >회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                  행위</span
                >
              </li>
              <li>
                <span>제3자의 진료 행위를 선전하거나 폄훼하는 행위</span>
              </li>
              <li>
                <span>상품을 판매하거나 판매를 알선하는 행위</span>
              </li>
              <li>
                <span>기타 부적절하다고 판단하는 행위</span>
              </li>
            </ul>
            <ol style="list-style-type: undefined; margin-left: 0cmundefined">
              <li>
                <span
                  >전항 각호의 정보 또는 기타 회사가 사이트 운영상 부적절하다고
                  판단한 정보가 회사의 서비스(홈페이지/모바일 웹, 앱)에
                  게시되거나 사이트와 링크된 곳에 게시된 경우, 회사는 회원 또는
                  기타 정보의 게시를 행한 자의 승낙 없이 회사의 서비스에 게재된
                  당해 정보를 삭제하거나 링크를 단절할 수 있습니다. 단, 회사가
                  이러한 정보의 삭제, 링크의 절단 등을 할 의무를 지지는
                  않습니다.</span
                >
              </li>
            </ol>
            <p>
              <span> </span>
            </p>
            <h6 class="sub">제6장 기타</h6>
            <h6 class="sub">제21조 (저작권의 귀속 및 이용제한)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >회사가 작성한 저작물에 대한 저작권, 기타 지적 재산권은 회사에
                  귀속합니다.</span
                >
              </li>
              <li>
                <span
                  >회원은 헬스앤모어㈜ 인터넷 관련 서비스(홈페이지/모바일 웹,
                  앱)을 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제,
                  송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로
                  이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제22조 (광고의 게재)</h6>

            <p>
              <span>1. </span
              ><span
                >회사는 서비스 운영과 관련하여 홈페이지, 서비스화면, 문자메시지,
                전자우편, 어플리케이션 푸시 알림 등에 광고 등을 게재할 수
                있습니다. 다만, 회사가 광고 등을 문자메시지, 전자우편,
                어플리케이션 푸시 알림 등을 이용하여 발송하는 경우, 수신 동의
                여부를 확인한 후 수신 동의한 회원에 한하여 이를
                발송합니다.</span
              >
            </p>
            <p>
              <span>2. </span
              ><span
                >회사는 광고주의 광고 내용의 진실성을 담보하지 않습니다.
                서비스상에 게재되어 있거나 연결사이트를 통한 광고주의 판촉활동에
                회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과
                손해에 대해 책임을 지지 않습니다.</span
              >
            </p>
            <h6 class="sub">제23조 (마일리지 운영 및 관리)</h6>

            <p>
              <span>1. </span
              ><span
                >회원이 서비스를 이용하여 회사로부터 무상으로 제공받은
                마일리지는 회사가 정한 기한 내에만 이용이 가능합니다.</span
              >
            </p>
            <p>
              <span>2. </span
              ><span
                >회사는 서비스의 효율적 운영을 위해 사전 공지 후 마일리지의 일부
                또는 전부를 조정할 수 있으며, 마일리지는 회사가 정한 기간에 따라
                주기적으로 소멸할 수 있습니다.</span
              >
            </p>
            <p>
              <span>3. </span
              ><span
                >회원이 마일리지를 부당하게 취득한 증거가 있을 때에는 회사는
                사전통지 없이 회원의 마일리지를 삭제할 수 있으며, 이와 관련하여
                회원 자격을 제한할 수 있습니다. 또한 부정한 방법으로 획득한
                마일리지를 이용하여 얻은 재화는 회사에게 귀속되며 회원에게 법적
                책임을 물을 수 있습니다. 부정한 취득에는 시스템 오류를 이용하여
                금전적 이득을 취한 경우를 포함합니다.</span
              >
            </p>
            <p>
              <span>4. </span
              ><span
                >회원 탈퇴 또는 회원자격 상실 시 마일리지 잔액 여부와 상관없이
                회원의 마일리지는 소멸하며 타인에게 양도할 수 없습니다.</span
              >
            </p>
            <p>
              <span>5. </span
              ><span
                >마일리지의 회수: 회사가 착오로 마일리지를 잘못 지급하거나 과다
                지급한 경우, 회원으로부터 되돌려 받을 수 있습니다.</span
              >
            </p>
            <h6 class="sub">제24조 (분쟁해결)</h6>

            <ol style="list-style-type: decimal; margin-left: 0cmundefined">
              <li>
                <span
                  >본 이용약관에 규정된 것을 제외하고 발생하는 서비스 이용에
                  관한 제반 문제에 관한 분쟁은 최대한 쌍방 합의에 의해
                  해결하도록 합니다.</span
                >
              </li>
              <li>
                <span
                  >회원이 헬스앤모어㈜ 인터넷 관련 서비스(홈페이지/모바일 웹,
                  앱) 이용과 관련하여 불만이 있거나 의견을 제시하고자 하는
                  경우에는 전자우편 또는 전화로 헬스앤모어㈜ 인터넷 관련
                  서비스(홈페이지/모바일 웹, 앱)에 대한 불만 사항 또는 의견을
                  제출하면 됩니다.</span
                >
              </li>
              <li>
                <span
                  >회사는 회원으로부터 제출되는 불만 사항 및 의견이 정당하다고
                  판단하는 경우 우선적으로 그 사항을 처리합니다. 다만, 신속한
                  처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을 즉시
                  통보해 드립니다.</span
                >
              </li>
            </ol>
            <h6 class="sub">제25조 (재판권 및 준거법)</h6>

            <p>
              <span
                >회사와 회원 간에 서비스 이용으로 발생한 분쟁에 관한 소송은
                민사소송법상의 관할법원으로 합니다.</span
              >
            </p>
            <!-- 회신동의 -->
            <h4 class="title" style="text-align: left; margin: 25px 0 35px 0">
              회신동의
            </h4>
            <h6 class="sub">1. 개인정보의 수집이용 목적</h6>

            <p>
              - 닥터리퍼(DrRefer)에서 제공하는 이벤트 정보를 앱 푸시 알림,
              이메일, 휴대폰 문자로 전송
            </p>
            <h6 class="sub">2. 수집하려는 개인정보의 항목</h6>
            <p>- 개인식별정보: 성명, 성별, e-mail, 휴대폰번호, 주소 등</p>
            <p>- 회원 ID, 접속 일시, 광고식별자(ADID, IDFA), SNS 아이디 등</p>
            <h6 class="sub">3. 개인정보의 보유 및 이용기간</h6>
            <p>
              - 이용자가 동의를 철회하거나 이용자의 탈퇴시까지 보유 및
              이용합니다.
            </p>
            <p>
              - 마케팅 정보 수신을 위한 개인정보 수집, 이용에 동의할 경우,
              귀하의 연락처, 이메일, 앱 푸시 알림을 통하여 재화나 서비스의 홍보
              또는 판매 권유 등을 위한 연락을 할 수 있습니다.
            </p>
            <p>
              - 귀하는 마케팅 정보 수신을 위한 개인정보 수집, 이용을 거부할 수
              있으며, 거부 시에는 마케팅정보 수신이 제한되지만, 닥터리퍼
              서비스는 계속 이용할 수 있습니다.
            </p>
            <p></p>
            <p></p>
            <p>
              ※마케팅 수신동의를 하시면, 각종 이벤트 정보를 빠르게 안내 받을 수
              있습니다.
            </p>
            <h6 class="sub">제1조 (약관의 정의)</h6>
            <p>
              <span>
               - 이 약관은 헬스앤모어㈜(이하 &lsquo;회사&rsquo;라 한다)에서 제공하는 
               '병원 광고/판촉물' 제작 서비스(이하"서비스"라 한다)를 이용함에 있어 
                헬스앤모어와 개발의뢰병원/업체(이하"의뢰인"이라 한다)간의 권리, 
                의무 및 책임사항 규정을 목적으로 하고 있습니다. 
              </span>
            </p>
            <p>
              <span>
                ※ 본 약관은 계약서와 동일한 효력을 발휘합니다.
              </span>
            </p>
            <h6 class="sub">제2조 (사용계약의 성립과 서비스 제공 시점)</h6>
            <p>
              <span>
                  - 계약성립은 웹사이트에서 의뢰인이 본 약관에 동의 하여 
                  디자인 상품 결제를 진행하고 각 타입별 선금이 입금된 시점을 
                  기준으로 서비스의 제공이 시작되는 것으로 합니다. 
              </span>
            </p>
            <p>
              <span>
                  - 헬스앤모어는 신청서 접수시 의뢰인과 전화 통화나 이메일을 통해 
                  확인통지를 하여야 하며 사정에 따라 서비스 제공 시점이 조정 변경될 수 있습니다.
              </span>
            </p>
            <h6 class="sub">제3조 (서비스 신청에 대한 제한적 거부 행사)</h6>
            <p>
              <span>
                - 헬스앤모어는 다음에 해당하는 경우에는 서비스를 제한적으로 거부 할 수 있습니다.
              </span>
            </p>
            <p>
              <span>
                ① 결제대행서비스의 통신 장애에 의한 결제 에러.
              </span>
            </p>
            <p>
              <span>
                ② 의뢰인이 해당 서비스에 근거하는 비용을 지불하지 아니한 경우 또는 연체하는 경우.
              </span>
            </p>
            <p>
              <span>
                ③ 헬스앤모어 웹사이트와 운용상의 하드웨어적인 장애로 인한 경우.
              </span>
            </p>
            <p>
              <span>
                ④ 헬스앤모어 웹사이트와 운용상의 하드웨어적인 장애로 인한 경우.
              </span>
            </p>
            <p>
              <span>
                ⑤ 각 품목에 해당하는 기본디자인 및 서비스제작품목은 유효기간을 3개월로 
                제한을 둡니다.(기한 이후의 작업은 유료로 청구됩니다.)
              </span>
            </p>
            <h6 class="sub">제4조 (신청자 정보관리)</h6>
            <p>
              <span>
                - 상품 결제시 기재된 사용자정보 및 각종 인적 사항에 대하여 헬스앤모어는 
                이를 디자인제작 대행사를 제외한 외부로 누설할 수 없으며 병원 광고/판촉물제작 시 
                참고사항의 목적으로만 사용하여야 합니다. 
              </span>
            </p>
            <p>
              <span>
                - 헬스앤모어의 잘못으로 인해 누출된 정보로 의뢰인이 피해를 보았다면 
                의뢰인은 헬스앤모어에게 법적인 책임을 물을 수 있습니다.
              </span>
            </p>
            <h6 class="sub">제5조 (대금 지급 방법)</h6>
            <p>
              <span>
                - 헬스앤모어는 병원 광고/판촉물을 계좌이체, 신용카드 결제 방법으로만 가능하며, 
                선입금 100%를 원칙으로 합니다.(부가세 포함)
              </span>
            </p>
            <p>
              <span>
                - 입금 확인 후 병원 광고/판촉물 개발에 착수하며 작업완료 후 메일로 
                병원 광고/판촉물 원본을 제공합니다.
              </span>
            </p>
            <p>
              <span>
                - 상품 결제시 기재된 사용자 정보 및 각종 인적 사항에 대하여 헬스앤모어는 
                이를 디자인제작 대행사를 제외한 외부로 누설할 수 없으며 병원 광고/판촉물제작 시 
                참고사항의 목적으로만 사용하여야 합니다. 
              </span>
            </p>
            <p>
              <span>
                 - 헬스앤모어의 잘못으로 인해 누출된 정보로 의뢰인이 피해를 보았다면 
                 의뢰인은 헬스앤모어에게 법적인 책임을 물을 수 있습니다.
              </span>
            </p>
            <h6 class="sub">제6조 (서비스 해지 및 변경과 그에 따른 저작권 행사)</h6>
            <p>
              <span>
                - 의뢰인으로부터 서비스 변경 및 취소 요청이 있을 때에는 
                헬스앤모어는 이를 요청에 따라 처리하며 개발작업 착수 이전에 
                서비스 해지 신청이 요구 되었을 때는 지급된 선불 전액을 의뢰인에게 환급하여야 합니다.
              </span>
            </p>
            <p>
              <span>
                - 그러나 시안 개발이 진행되어 시안을 제시한 경우나 신청 후 1일이 경과한 경우에는 
                의뢰인이 서비스 취소를 요구하더라도 이미 지급된 선금은 
                시안 개발에 소요된 경비이므로 환불되지 않습니다.
              </span>
            </p>
            <p>
              <span>
                 - 또한 제시된 시안에 대해서는 일체의 법적 소유가 
                 헬스앤모어에게 있으므로 의뢰인은 이를 사용할 수 없으며, 
                 헬스앤모어의 동의 없이 의뢰인이 이를 무단으로 사용할 경우 
                 헬스앤모어는 저작권을 행사할 수 있습니다.
              </span>
            </p>
            <h6 class="sub">제7조 (서비스 제공 기간과 내용)</h6>
            <p>
              <span>
                - 병원 광고/판촉물개발 서비스의(1차 시안과 2차 시안, 추가되는 수정안)기간과 내용은 
                홈페이지에 기재된 내용을 기본으로 하며 이는 서비스의 종류와 
                시안 접수 후 의뢰인의 검토 기간에 따라 달라질 수 있습니다.
              </span>
            </p>
            <p>
              <span>
                - 또한 의뢰인과의 협의를 통해 그 기간은 조정될 수 있습니다.
              </span>
            </p>
            <p>
              <span>
                - 추가되는 기본디자인 및 서비스(응용디자인)에 대한 내용은 
                상품 결제후 헬스앤모어와 디자인제작 대행사의 확인 후 제작되며, 
                유효기간이 지난 작업은 (접수후 3개월미만) 내용에 따라 
                홈페이지에 책정된 금액을 적용합니다.
              </span>
            </p>
            <p>
              <span>
                - 헬스앤모어에서 제공되는 모든 시안은 고객의 이메일 혹은 
                당사의 웹하드에 제공되는 것을 원칙으로 하며 
                부득이한 경우에 한하여 다른 형태를 취할 수 있습니다.
              </span>
            </p>
            <h6 class="sub">제8조 (헬스앤모어의 의무)</h6>
            <p>
              <span>
                - 헬스앤모어는 이 약관에 제시한 내용을 충실히 따르고 의뢰인에게 
                최상의 서비스 제공을 위해 최선을 다하여야 합니다.
              </span>
            </p>
            <p>
              <span>
                - 의뢰인에 대한 일체의 비밀을 유지하며 변경사항이 발생할 경우 
                즉시 의뢰인과 연락을 취하여 이를 상의하고 의뢰인의 요구에 충실하게 응해야 합니다.
              </span>
            </p>
            <p>
              <span>
                - 제작이 완료된 후에 의뢰인의 요구로 수정이나 변경 사항 등에 대한 요구가 있을 시 
                이를 이행해야 합니다.(단 추가적인 비용이 요구 될 때는 상담 후 결정합니다.)
              </span>
            </p>
            <h6 class="sub">제9조 (의뢰인의 의무)</h6>
            <p>
              <span>
                - 의뢰인은 본 약관의 내용을 충실히 이행하며 불이행에 의한 
                피해에 대해서는 헬스앤모어 에게 책임을 물을 수 없습니다.
              </span>
            </p>
            <p>
              <span>
                - 의뢰인의 정보변경이나 기타 추가적인 내용이 있을 시에는 
                헬스앤모어 담당자에게 이를 즉시 통보하여야 합니다.
              </span>
            </p>
            <h6 class="sub"> 제10조 (병원 광고/판촉물개발 서비스의 종결과 A/S) </h6>
            <p>
              <span>
                 - 본 약관과 상품결제에 의해 이루어진 병원 광고/판촉물개발 서비스의 종결은 
                 의뢰인이 헬스앤모어 제작물에 대한 결과물을 발송하여 의뢰인이 
                 이를 이상 없이 수령한 시점을 기준으로 종결됩니다.
              </span>
            </p>
            <p>
              <span>
                 - 서비스의 종결과 함께 제공된 병원 광고/판촉물은 의뢰인에게 영구 귀속됩니다.
              </span>
            </p>
            <h6 class="sub"> 제11조 (약관의 효력 및 변경)</h6>
            <p>
              <span>
                - 이 약관은 의뢰인이 병원 광고/판촉물개발 신청 시 웹페이지에 
                기재된 내용에 동의함으로써 효력을 발휘합니다.
              </span>
            </p>
            <p>
              <span>
                - 약관의 변경이 발생할 경우 헬스앤모어는 이를 웹페이지에 공시하며, 약관 변경 이전에 
                서비스를 신청한 의뢰인에 대해서는 계약 당시의 약관을 적용하며, 
                이후 추가되는 서비스 신청에 대해서만 변경된 약관을 적용합니다.
              </span>
            </p>
            <h6 class="sub"> 제12조 (분쟁 해결)</h6>
            <p>
              <span>
                - 의뢰인의 불만사항이나 의견은 신속하게 처리하며, 신속한 처리가 곤란한 경우 
                의뢰인에게 그 사유와 처리 일정에 대한 자세한 내용을 통보하여 서로간에 조정점을 찾도록 합니다.
              </span>
            </p>
            <h6 class="sub"> 제13조 (유보 사항)</h6>
            <p>
              <span>
                - 본 약관에 기재되지 않은 사항에 대해서는 헬스앤모어와 의뢰인간에 상호 협력하는 것을 원칙으로 하고 
                합의가 불가능할 경우에는 일반적인 관례에 따라 처리합니다.
              </span>
            </p>
            <p>
              <span>
                (시행일) 이 약관은 2021년 08월 25일부터 시행합니다.
              </span>
            </p>
            <p>
              <span> </span>
            </p>
            <p></p>
            <p>
              <br />
            </p>
          </div>

          <div class="" v-if="agreeCheckNum === 99">
            <div class="comment">
              <p>문의사항 동의 준비중</p>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <button type="button" class="prev" @click="agreeCancle()">
            취소
          </button>
          <button
            type="button"
            class="confirm"
            @click="agreeCheck(agreeCheckNum)"
            v-text="btnName"
          ></button>
        </div>
      </div>
    </template>
  </modal-agreement>
</template>

<script>
import { mapMutations } from "vuex";
import ModalAgreement from "./ModalAgreement2.vue";

export default {
  props: {
    popupSetAgree: {
      footerLess: {
        type: Boolean,
      },
      isHuge: {
        type: Boolean,
      },
      agreeCheckNum: {
        type: Number,
      },
    },
  },
  components: {
    ModalAgreement,
  },
  data() {
    return {
      input: "",
      valid: false,
      agreeCheckNum: this.popupSetAgree.agreeCheckNum,
      footerLess: this.popupSetAgree.footerLess,
      isHuge: this.popupSetAgree.isHuge,
      btnName: "동의",
      marketing: [
        {
          flag: false,
          title: "Email 수신동의",
        },
        {
          flag: false,
          title: "SMS 수신동의",
        },
      ],
    };
  },
  watch: {},
  mounted() {
    document.querySelector("body").classList.add("spreaded");
  },
  computed: {},
  methods: {
    ...mapMutations("basic", ["SET_AGREEMENT_POPUP2"]),
    checkMarketing(order) {
      this.marketing[order].flag = !this.marketing[order].flag;
    },
    agreeCheck(order) {
      if (this.agreeCheckNum === 99) {
        this.$emit("agree-checked");
      } else {
        this.$emit("agree-checked", order);
      }
      this.SET_AGREEMENT_POPUP2(false);
    },
    agreeCancle() {
      this.SET_AGREEMENT_POPUP2(false);
    },
  },
  created() {},
  beforeDestroy() {
    if (document.querySelector("body").classList.contains("spreaded")) {
      document.querySelector("body").classList.remove("spreaded");
    }
  },
};
</script>

<style lang="scss" scoped>
.agreement-pop {
  margin: 0 84px;
  .a-header {
    margin: 40px 0;
    text-align: center;
    text-align: center;
    .img-wrap {
      margin: 0 0 15px 0;
      img {
      }
    }
    span {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 13px;
      letter-spacing: normal;
      text-align: center;
      color: #474747;
      font-family: "SUIT";
    }
  }
  .a-content {
    border: 1px solid #646464;
    &.marketting {
      border: 0;
      .text-wrap {
        border: 1px solid #646464;
      }
    }
    .text-wrap {
      overflow-y: scroll;
      max-height: 430px;
      padding: 20px 108px 45px;
      background: #f8f8f8;
      h4.title {
        font-family: "SUIT";
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 27px;
        letter-spacing: normal;
        text-align: center;
        color: #474747;
        margin: 10px 0;
      }
      h6.sub {
        font-family: "AppleSDGothicNeoM00";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: normal;
        text-align: left;
        color: #474747;
        margin: 10px 0;
      }
      h6.sub + h6.sub {
      }
      span {
        font-family: "AppleSDGothicNeoR00";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        text-align: left;
        color: inherit;
      }
      p {
        margin: 8px 0 0 0;
        font-family: "AppleSDGothicNeoR00";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: normal;
        text-align: left;
        color: #474747;
        &:first-of-type {
          margin: 0;
        }
      }
    }
    .comment {
      overflow-y: scroll;
      max-height: 460px;
      .textarea-wrap {
        width: 100%;
        border: 1px solid #707070;
        textarea {
          font-family: "AppleSDGothicNeoR00";
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #231815;
          border: 0;
          width: inherit;
          background: transparent;
          padding: 15px;
          overflow: hi dden;
          resize: none;
          line-height: 28px;
          height: 240px;
          -moz-user-select: auto;
          -webkit-user-select: auto;
          -ms-user-select: auto;
          user-select: auto;
          &::placeholder {
            color: #d0d0d0;
          }
        }
      }
      h5 {
        font-family: "SUIT";
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
      }
      p {
        font-family: "SUIT";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 27px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        position: relative;
      }
    }
    .checkbox {
      margin: 20px 0 35px 0;
      display: flex;
      justify-content: center;
      .item {
        display: flex;
        margin: 0 0 0 20px;
        align-items: center;
        cursor: pointer;
        &:first-of-type {
          margin: 0;
        }
        .check {
          margin: 0 10px 0 0;
          img {
          }
        }
        h4 {
        }
      }
    }
  }
  .btn-wrap {
    background: transparent;
    position: relative;
    width: auto !important;
    display: flex;
    height: 51px;
    margin: 40px auto 40px;
    justify-content: center;
    button {
      border: 0;
      background: transparent;
      width: 190px;
      margin: 0 0 0 40px;
      height: inherit;
      color: #fff;
      font-family: "SUIT";
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:first-of-type {
        margin: 0;
      }
      &.prev {
        background: #474747;
      }
      &.confirm {
        background: #146f83;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modal-wrapper {
    .modal-container {
      &.huge {
        width: calc(100% - 30px) !important;
        padding: 0 !important;
        min-height: inherit;
        overflow-y: inherit;
      }
      .modal-flex {
        min-height: inherit;
        .modal-body {
          .agreement-pop {
            margin: 0;
            width: inherit;
            .a-header {
              margin: 20px 0;
              .img-wrap {
                margin: 0 0 8px 0;
              }
            }
            .a-content {
              border: 1px solid #646464;
              &.marketting {
                border: 0;
                .text-wrap {
                  border: 1px solid #646464;
                }
              }

              .text-wrap {
                padding: 0 12.5px;
                background: #f8f8f8;
                max-height: 330px;
              }
            }
            .btn-wrap {
              width: calc(100% - 30px) !important;
              margin: 20px auto 30px;
              height: 44px;
              button {
                margin: 0 0 0 20px;
                width: calc(50% - 10px);
                font-size: 20px;
                line-height: 31px;
                &:first-of-type {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
