<template>
  <transition name="modal">
    <div class="modal-mask" @click="overlayClick($event)">
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop :class="{ huge: huge }">
          <div class="modal-flex">
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    huge: {
      type: Boolean,
      default: false,
    },
    footerLess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapMutations("basic", ["SET_AGREEMENT_POPUP2"]),
    overlayClick(event) {
      const target = event.target;
      if (target == event.currentTarget.querySelector(".modal-container")) {
        return false;
      }
      const buttonTags = event.currentTarget
        .querySelector(".btn-wrap button")
        .querySelectorAll("button");
      if (buttonTags == target) return false;

      this.SET_AGREEMENT_POPUP2(false);
    },
    closePop() {
      this.SET_AGREEMENT_POPUP2(false);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped>
/* 반응형 */
@media screen and (max-width: 960px) {
  .modal-wrapper {
    .modal-container {
      &.huge {
        width: calc(100% - 30px) !important;
        padding: 0 !important;
        min-height: inherit;
        overflow-y: inherit;
      }
      .modal-flex {
        min-height: inherit;
        .modal-body {
        }
      }
    }
  }
}
</style>
