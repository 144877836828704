<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="text-center agreement-title-top" cols="12">
          개인정보조회 동의
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col class="agreement-info" cols="11">
              <p>필수 동의 항목에 동의하시면 환자(또는 보호자)의 휴대폰 번호가 진료 받을 병원으로 전송됩니다.</p>
              <p>해당 병원은 진료 예약에 편의를 제공할 수 있습니다.</p>
              <p>진료 내용을 의사선생님께 회신하여 진료의 연속성을 추구할 수 있습니다.</p>
              <p>필수 동의 항목에 동의하지 않더라도, 진료 받는 데는 전혀 불이익이 없습니다.</p>
              <p class="space">*종이 진료의뢰서를 가지고 가시면 진료 시 도움이 됩니다.</p>
            </v-col>
            <v-col class="agreement-total" cols="11" @click="allCheckAgree">
              <v-row>
                <v-col cols="10" class="agreement-total-text">
                  전체 약관 동의
                </v-col>
                <v-col cols="2" class="all-check">
                  <div class="check all-none-check" v-if="!agreeTotalFlag">
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                  <div class="check all-checked" v-else>
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-divider class="black-divider"></v-divider>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="agreement-total" cols="11" @click="readAgreement(0)">
              <v-row>
                <v-col cols="10" class="agreement-sub-text">
                  [필수] 개인정보 및 진료정보 제공 동의
                </v-col>
                <v-col cols="2" class="sub-check">
                  <div class="check sub-none-check" v-if="!agreeUseFlag">
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                  <div class="check sub-checked" v-else>
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="agreement-total" cols="11" @click="readAgreement(1)">
              <v-row>
                <v-col cols="10" class="agreement-sub-text">
                  [필수] 환자 연락처 열람 동의
                </v-col>
                <v-col cols="2" class="sub-check">
                  <div class="check sub-none-check" v-if="!agreePhoneFlag">
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                  <div class="check sub-checked" v-else>
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="agreement-total" cols="11" @click="readAgreement(2)">
              <v-row>
                <v-col cols="10" class="agreement-sub-text">
                  [선택] 병원 이용 만족도 조사 수신 동의
                </v-col>
                <v-col cols="2" class="sub-check">
                  <div class="check sub-none-check" v-if="!agreeSurveyFlag">
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                  <div class="check sub-checked" v-else>
                    <img src="@/assets/images/ck_check_white.svg" />
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="agreement-content" cols="11">
              연락처 <span>(연락받을 휴대폰 번호를 '-'없이 입력해 주세요.)</span>
            </v-col>
            
            <v-col cols="11">
              <v-text-field
                background-color="#f5f9fa"
                outlined
                rounded
                clearable
                placeholder="연락처를 입력해주세요"
                class="agreement-phone"
                v-model="form.agreeHpNo.value"
                @input="isAgreeHpNo()"
              ></v-text-field>
            </v-col>
              

            <v-col class="agreement-content" cols="11">
              환자와의 관계
            </v-col>
            
            <v-col cols="11">
              <v-select
                placeholder="관계를 선택해주세요"
                v-model="form.relation.select"
                :items="form.relation.options"
                item-text="rel"
                item-value="value"
                :rules="form.relation.relation_rule"
                :searchable="false"
                @input="isRelation()"
                rounded
                background-color="#f5f9fa"
                height="60px"
                class="relation-select"
              ></v-select>
            </v-col>
            <v-col cols="11" class="text-center">
              <v-btn
                class="agree-btn"
                depressed
                dark
                rounded
                height="60px"
                width="150px"
                @click="agree()"
                :class="
                  {
                    agreeBtnActive: 
                      agreeUseFlag === true && 
                      agreePhoneFlag === true &&
                      form.relation.flag === true &&
                      form.agreeHpNo.flag === true
                  }
                "
              >
                확인
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <agreement-popup
      @agree-checked="checkAgreement($event)"
      v-if="checkAgreementPopup2"
      :popupSetAgree="popupSetAgree"
    />
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import Directive from "@/components/join/Directive";
import AgreementPopup from "@/components/modal/AgreementPopup2";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Directive,
    AgreementPopup,
    DefaultPopup,
  },
  data() {
    return {
      agreeTotalFlag: false,
      agreeUseFlag: false,
      agreePhoneFlag: false,
      agreeSurveyFlag: false,

      directiveInfo: {
        title: "개인정보제공동의",
        isTitleNewLine: false,
        content: null,
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
        isMobile: true,
      },
      agreeChecked: false,
      agreement: [
        {
          flag: false,
          type: "[필수] 닥터리퍼 이용동의",
          errorTxt: "필수동의를 진행해주세요",
          hasError: false,
        },
        {
          flag: false,
          type: "[필수] 환자연락처 열람동의",
        },
        {
          flag: false,
          type: "[선택] 진료만족도평가 수신동의",
        },
      ],
      form: {
        consenter: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*동의자명을 정확히 입력해주세요",
        },
        relation: {
          options: [
            { rel: "본인", value: "1" },
            { rel: "배우자", value: "2" },
            { rel: "직계존속(부모)", value: "3" },
            { rel: "직계비속(자녀)", value: "4" },
            { rel: "배우자의 직계존속", value: "5" },
            { rel: "형제자매", value: "6" },
          ],
          select: "",
          relation_rule: [
            v => !!v || '(필수)관계를 선택해주세요.'
          ],
          flag: false,
          hasError: false,
          errorTxt: "*동의자와의 관계를 입력해주세요",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-을 제외한 숫자만을 입력해주세요",
        },
        gender: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-을 제외한 숫자만을 입력해주세요",
        },
        agreeId: {
          value: "",
          flag: false,
        },
        agreeHpNo: {
          value: "",
          flag: false,
        } 
        
      },
      isView: 0,
      popupSet: {},
      popupSetAgree: {},
      apiSet: {},
    };
  },
  created() {
    this.getStoreAgreement();
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkAgreementPopup2"]),
    ...mapGetters("request", ["GET_AGREE_RESULT", "GET_REQ_AGREE_RESULT"]),
    genetateHyphen() {
      return (numData, isBirth) => {
        //console.log("가져온 번호들", numData);
        //console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_AGREEMENT_POPUP2"]),
    ...mapActions("request", [
      "AGREE_SUBMIT",
      "BRING_AGREE_RESULT",
      "AGREE_FINISHED",
    ]),
    isRelation() {
      this.form.relation.flag = true;
      this.form.relation.hasError = false;
      this.initContent();
      //console.log(this.form.relation.select);
    },
    initContent() {
      this.form.consenter.hasError = false;
      this.form.consenter.value = "";
      this.form.birth.value = "";
      this.form.birth.hasError = false;
    },
    winClose() {
      window.open("", "_self", "");
      window.close();
    },
    async getStoreAgreement() {
      try {
        await this.AGREE_FINISHED(this.$route.params.code).then((data) => {
          //console.log("성공", this.GET_REQ_AGREE_RESULT);
          // if (!this.GET_REQ_AGREE_RESULT) {
          if (data.isnull === true) {
            this.form.agreeId.value = this.$route.params.code;
            this.form.agreeId.flag = true;
          } else {
            this.SET_POPUP(true);
            this.isView = 2;
            this.popupSet.title = "요청실패";
            this.popupSet.content =
              "만료되었거나 동의내역을 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "요청실패";
          this.popupSet.content = " 동의내역을 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    checkFlag() {
      if (this.form.relation.select !== "1") {
        if (
          !this.form.agreeId.flag ||
          !this.agreeUseFlag ||
          !this.agreePhoneFlag ||
          !this.form.relation.flag ||
          !this.form.agreeHpNo.flag
        ) {
          if (!this.form.agreeId.flag) {
            //console.log("동의번호 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의번호 확인";
            this.popupSet.content = "동의번호를 불러오지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.agreeUseFlag) {
            //console.log("동의여부 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의여부 확인";
            this.popupSet.content = "필수 동의항목을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.agreePhoneFlag) {
            //console.log("동의여부 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의여부 확인";
            this.popupSet.content = "필수 동의항목을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.form.agreeHpNo.flag) {
            //console.log("연락처" 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "연락처 확인";
            this.popupSet.content = "연락처를 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.form.relation.hasError = true;
          }
          if (!this.form.relation.flag) {
            //console.log("관계 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "관계여부 확인";
            this.popupSet.content = "관계 여부를 선택해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.form.relation.hasError = true;
          }
          // if (!this.form.consenter.flag) {
          //   //console.log("동의자 확인");
          //   this.form.consenter.value = "";
          //   this.form.consenter.hasError = true;
          // }
          if (!this.form.birth.flag) {
            //console.log("생년월일 확인");
            this.form.birth.value = "";
            this.form.birth.hasError = true;
          }
          return false;
        }
        return true;
      } else {
        if (
          !this.form.agreeId.flag ||
          !this.agreeUseFlag ||
          !this.agreePhoneFlag ||
          !this.form.relation.flag
        ) {
          if (!this.form.agreeId.flag) {
            //console.log("동의번호 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의번호 확인";
            this.popupSet.content = "동의번호를 불러오지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.agreeUseFlag) {
            //console.log("동의여부 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의여부 확인";
            this.popupSet.content = "필수 동의항목을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.agreePhoneFlag) {
            //console.log("동의여부 확인");
            this.SET_POPUP(true);
            this.popupSet.title = "동의여부 확인";
            this.popupSet.content = "필수 동의항목을 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          if (!this.form.relation.flag) {
            //console.log("관계 확인");
            this.form.relation.hasError = true;
          }
          return false;
        }
        return true;
      }
    },
    async agree() {
      if (this.checkFlag()) {
        //console.log("재료1 :", this.agreement[0].flag);
        //console.log("재료2 :", this.agreement[1].flag);
        //console.log("재료3 :", this.agreement[2].flag);
        try {
          const payload = {
            agreeId: this.form.agreeId.value,
            mndYn1: this.agreeUseFlag ? "Y" : "N",
            mndYn2: this.agreePhoneFlag ? "Y" : "N",
            optYn1: this.agreeSurveyFlag ? "Y" : "N",
            optyn2: "Y",
            optYn3: "Y",
            agreeNm: this.form.consenter.value,
            agreeHpNo: this.form.agreeHpNo.value,
          };
          if (this.form.birth.value !== "" && this.form.gender.value !== "") {
            payload.agreeBirth =
              this.form.birth.value + "-" + this.form.gender.value;
          }
          // if (this.form.relation.value === "본인") {
          //   payload.rltnType = 1;
          // } else if (this.form.relation.value === "배우자") {
          //   payload.rltnType = 2;
          // } else if (this.form.relation.value === "직계존속(부모)") {
          //   payload.rltnType = 3;
          // } else if (this.form.relation.value === "직계비속(자녀)") {
          //   payload.rltnType = 4;
          // } else if (this.form.relation.value === "배우자의 직계존속") {
          //   payload.rltnType = 5;
          // } else if (this.form.relation.value === "형제자매") {
          //   payload.rltnType = 6;
          // }

          payload.rltnType = this.form.relation.select;

          //console.log("전송할 동의정보", payload);
          await this.AGREE_SUBMIT(payload).then(() => {
            this.isView = 1;
            /**
            setTimeout(() => {
              this.SET_POPUP(false);
              document.querySelector("a.hidden").click();
            }, 800);
             */

            this.$router.push("/v/req/agreefinish");
          });
        } catch (error) {
          console.log("error :", error.data.message);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = "입력정보가 서버로 전송되지 못했습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
          /**
          setTimeout(() => {
            this.SET_POPUP(false);
            document.querySelector("a.hidden").click();
          }, 800);
           */
        }
      } else {
        if (
          !this.agreeUseFlag &&
          !this.agreePhoneFlag &&
          !this.form.agreeHpNo.flag && 
          !this.form.agreeId
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "입력값 확인";
          this.popupSet.content = "입력값을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    allCheckAgree() {
      this.agreeTotalFlag = !this.agreeTotalFlag;
      this.agreeUseFlag= this.agreeTotalFlag;
      this.agreePhoneFlag= this.agreeTotalFlag;
      this.agreeSurveyFlag= this.agreeTotalFlag;
    },
    checkAgreement(order) {
      //console.log("order : " + order);
      //this.agreement[order].flag = !this.agreement[order].flag;
      
      if (order === 0) {
        this.agreeUseFlag = !this.agreeUseFlag;
      } else if (order === 1) {
        this.agreePhoneFlag = !this.agreePhoneFlag;
      } else if (order === 2) {
        this.agreeSurveyFlag = !this.agreeSurveyFlag;
      } 

      this.checkOther();
    },
    checkOther() {
      // for (let i = 0; i < this.agreement.length; i++) {
      //   if (!this.agreement[i].flag) {
      //     this.agreeChecked = false;
      //     return false;
      //   }
      // }
      // this.agreeChecked = true;

      if (
        this.agreeUseFlag === true &&
        this.agreePhoneFlag === true &&
        this.agreeSurveyFlag === true
      ) {
        this.agreeTotalFlag = true;
      } else {
        this.agreeTotalFlag = false;
      }


    },
    readAgreement(type) {
      //console.log("Type", type);

      if (type === 0) {
        if (this.agreeUseFlag) {
          this.agreeUseFlag = !this.agreeUseFlag;
          this.agreeTotalFlag = false;
        } else {
          this.popupSetAgree.agreeCheckNum = type;
          this.SET_AGREEMENT_POPUP2(true);
          this.popupSetAgree.footerLess = true;
          this.popupSetAgree.isHuge = true;
        }
      } else if (type === 1) {
        if (this.agreePhoneFlag) {
          this.agreePhoneFlag = !this.agreePhoneFlag;
          this.agreeTotalFlag = false;
        } else {
          this.popupSetAgree.agreeCheckNum = type;
          this.SET_AGREEMENT_POPUP2(true);
          this.popupSetAgree.footerLess = true;
          this.popupSetAgree.isHuge = true;
        }
      } else {
        if (this.agreeSurveyFlag) {
          this.agreeSurveyFlag = !this.agreeSurveyFlag;
          this.agreeTotalFlag = false;
        } else {
          this.popupSetAgree.agreeCheckNum = type;
          this.SET_AGREEMENT_POPUP2(true);
          this.popupSetAgree.footerLess = true;
          this.popupSetAgree.isHuge = true;
        }

      }

      // if (this.agreement[type].flag) {
      //   this.agreement[type].flag = !this.agreement[type].flag;
      //   this.agreeChecked = false;
      // } else {
      //   this.popupSetAgree.agreeCheckNum = type;
      //   this.SET_AGREEMENT_POPUP2(true);
      //   this.popupSetAgree.footerLess = true;
      //   this.popupSetAgree.isHuge = true;
      // }
    },
    checkConsenter() {
      this.form.consenter.flag = false;
      this.form.consenter.hasError = true;
      const Consenter = this.form.consenter.value.replace(/\s/gi, "");
      if (Consenter === "") {
        this.form.consenter.flag = false;
        return false;
      }
      this.form.consenter.flag = true;
      this.form.consenter.hasError = false;
      return true;
    },
    checkRelation() {
      this.form.relation.flag = false;
      this.form.relation.hasError = true;
      const Relation = this.form.relation.value.replace(/\s/gi, "");
      if (Relation === "") {
        this.form.relation.flag = false;
        return false;
      }
      this.form.relation.flag = true;
      this.form.relation.hasError = false;
      return true;
    },
    checkGender() {
      this.form.gender.flag = false;
      this.form.gender.hasError = true;
      const Gender = this.form.gender.value.replace(/\s/gi, "");
      if (Gender === "") {
        this.form.gender.flag = false;
        this.form.gender.hasError = true;
        this.form.gender.errorTxt = "*생년월일을 입력해주세요.";
        return false;
      }
      const isGender = /^[0-9]{1}$/;
      if (!isGender.test(Gender)) {
        this.form.gender.errorTxt = "*YYMMDD 형식에 맞춰서 입력해주세요.";
        this.form.gender.flag = false;
        this.form.gender.hasError = true;
        return false;
      }
      this.form.gender.flag = true;
      this.form.gender.hasError = false;
      this.form.gender.errorTxt = "";
      return true;
    },
    checkBirth() {
      this.form.birth.flag = false;
      this.form.birth.hasError = true;
      const birth = this.form.birth.value.replace(/\s/gi, "");
      if (birth === "") {
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        this.form.birth.errorTxt = "*생년월일을 입력해주세요.";
        return false;
      }
      const isBirth = /^[0-9]{6}$/;
      if (!isBirth.test(birth)) {
        this.form.birth.errorTxt = "*생년월일 형식에 맞춰서 입력해주세요.";
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        return false;
      }
      this.form.birth.flag = true;
      this.form.birth.hasError = false;
      this.form.birth.errorTxt = "";
      return true;
    },
    isAgreeHpNo() {
      const hpNo = this.form.agreeHpNo.value;

      if (hpNo === null || hpNo === undefined)
        hpNo = "";

      var hpNoBoo = /^[0-9]{8,14}$/;

      if (!hpNoBoo.test(hpNo)) {
        this.form.agreeHpNo.flag = false;
      } else {
        this.form.agreeHpNo.flag = true;
      }
    }
  },
  destroyed() {
    if (this.checkPopup !== undefined && this.checkPopup === true) {
      this.SET_POPUP(false);
    } else if (
      this.checkAgreementPopup2 !== undefined &&
      this.checkAgreementPopup2 === true
    ) {
      this.SET_AGREEMENT_POPUP2(false);
    }

    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
  .agreement-title-top {
    font-weight: 500;
    font-size: 34px;
    color: #146f83;
    margin: 50px 0px 50px 0px;
  }

  .agreement-info {
    padding: 35px 30px 35px 30px;
    color: #146f83;
    background-color: #f5f9fa;
    border-radius: 20px;
    margin-bottom: 50px;

    p {
      font-size: 16px;
      word-break: keep-all;
    }

    .space {
      margin: 40px 0px 40px 0px;
    }
  }

  .agreement-total {
    cursor: pointer;
    
    .agreement-total-text {
      font-size: 30px;
      font-weight: 500;
    }

    .agreement-sub-text {
      font-size: 24px;
      font-weight: 400;
    }
    
    
  }

  .black-divider {
    background-color: black;
  }

  .all-check {
    
    .check {
      float: right;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50px;
    }
  }
  

  .all-none-check {
    background-color: #e1e1e1;
    img {
      width: 60%;
      margin-top: 13px;
    }
  }

  .all-checked {
    background-color: #146f83;
    img {
      width: 60%;
      margin-top: 13px;
    }
  }

  .sub-check {
    
    .check {
      float: right;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 50px;
    }
  }

  .sub-none-check {
    background-color: #e1e1e1;
    img {
      width: 60%;
      margin-top: 12px;
    }
  }

  .sub-checked {
    background-color: #146f83;
    img {
      width: 60%;
      margin-top: 12px;
    }
  }

  .agreement-content {
    
    margin-top: 50px;;
    font-size: 30px;
    font-weight: 500;

    span {
      font-size: 20px;
      word-break: keep-all;
    }
  }

  .relation-select {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    top: -20px;
  }

  .agreement-phone {
    margin-top: 19px;
    font-size: 20px;
    font-weight: 400;
    position: relative;
    top: -20px;
  }

  .agree-btn {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 25px;
  }

  .agreeBtnActive {
    background-color: #146f83 !important;
  }
  
</style>

<style scoped>
  .v-text-field--outlined >>> fieldset {
    border: 0px;
  }
</style>